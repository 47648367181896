.company_gui_wrapper {
    width: 80vw;
    min-width: 400px;
    height: 60vw;
    min-height: 400px;
}

@media only screen and (min-width: 1300px) {
    .company_gui_wrapper {
        width: 1100px;
        height: 890px;
    }
}

/* @import "https://code.highcharts.com/css/stocktools/gui.css";
@import "https://code.highcharts.com/css/annotations/popup.css"; */

.chart:-webkit-full-screen {
    width: 100%;
    height: 100%;
}

.chart:-moz-full-screen {
    width: 100%;
    height: 100%;
}

.chart:-ms-fullscreen {
    width: 100%;
    height: 100%;
}

.chart:fullscreen {
    width: 100%;
    height: 100%;
}

.chart {
    width: 100%;
    float: left;
    height: 100%;
    position: relative;
}

.highcharts-draw-mode {
    cursor: crosshair;
}

.highcharts-bindings-wrapper * {
    box-sizing: content-box;
}

.highcharts-bindings-wrapper {
    display: block;
    width: 40px;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 0px;
}

.highcharts-stocktools-popup {
    width: 100%;
}

.highcharts-menu-wrapper {
    float: left;
    width: 40px;
    height: calc(100% - 50px);
    overflow: hidden;
    position: absolute;
    left: 0px;
    top: 0px;
    padding: 10px;
}

.highcharts-bindings-wrapper .highcharts-submenu-wrapper {
    display: none;
    position: absolute;
    z-index: 10;
    left: 0px;
    top: 0px;
    background: #fff;
    width: 40px;
}

.highcharts-bindings-wrapper .highcharts-arrow-wrapper {
    text-align: center;
    width: 40px;
    position: absolute;
    left: 10px;
    bottom: 10px;
    font-size: 1.5em;
}

.highcharts-bindings-wrapper .highcharts-arrow-wrapper > div {
    cursor: pointer;
}

.highcharts-bindings-wrapper .highcharts-arrow-down {
    background-size: cover;
    /* Safari */
    -webkit-transform: rotate(90deg);
    /* Firefox */
    -moz-transform: rotate(90deg);
    /* IE */
    -ms-transform: rotate(90deg);
    /* Opera */
    -o-transform: rotate(90deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    transform: rotate(90deg);
}

.highcharts-bindings-wrapper .highcharts-arrow-up {
    background-size: cover;
    outline: none;
    display: inline-block;
    width: 25px;
    cursor: pointer;
    -webkit-user-select: none;
    /* Chrome/Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+ */
    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    transform: rotate(-90deg);
}

.highcharts-bindings-wrapper .highcharts-arrow-right {
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
}

.highcharts-bindings-wrapper .highcharts-arrow-left.highcharts-arrow-right {
    /* Safari */
    -webkit-transform: rotate(0deg);
    /* Firefox */
    -moz-transform: rotate(0deg);
    /* IE */
    -ms-transform: rotate(0deg);
    /* Opera */
    -o-transform: rotate(0deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    transform: rotate(0deg);
}

.highcharts-bindings-wrapper .highcharts-arrow-left {
    /* Safari */
    -webkit-transform: rotate(180deg);
    /* Firefox */
    -moz-transform: rotate(180deg);
    /* IE */
    -ms-transform: rotate(180deg);
    /* Opera */
    -o-transform: rotate(180deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    transform: rotate(180deg);
}

.highcharts-bindings-wrapper ul {
    width: 40px;
    /* 30px spacing for arrows to scroll */
    margin: 0px;
    padding: 0px;
    float: left;
    transition: margin 250ms;
}

.highcharts-bindings-wrapper > ul {
    width: 40px;
    position: relative;
}

.highcharts-bindings-wrapper .highcharts-stocktools-toolbar li {
    list-style: none;
    margin-bottom: 3px;
    padding: 0px;
    clear: both;
    width: 100%;
    height: 40px;
    cursor: pointer;
    position: relative;
    background-color: #f7f7f7;
}

.highcharts-bindings-wrapper
    .highcharts-stocktools-toolbar
    li.highcharts-disabled-btn {
    cursor: default;
}

.highcharts-bindings-wrapper
    .highcharts-stocktools-toolbar
    li.highcharts-disabled-btn
    > .highcharts-menu-item-btn {
    opacity: 0.5;
}

.highcharts-bindings-wrapper
    .highcharts-stocktools-toolbar
    li.highcharts-disabled-btn.highcharts-active {
    background: #f7f7f7;
}

.highcharts-bindings-wrapper
    .highcharts-stocktools-toolbar
    li.highcharts-disabled-btn
    .highcharts-menu-item-btn:hover {
    background-color: transparent;
}

.highcharts-bindings-wrapper li > span.highcharts-menu-item-btn {
    display: block;
    float: left;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 32px 32px;
}

.highcharts-submenu-wrapper li > span.highcharts-menu-item-btn {
    width: 40px;
}

.highcharts-bindings-wrapper li > span.highcharts-submenu-item-arrow {
    float: left;
    width: 10px;
    height: 100%;
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.highcharts-bindings-wrapper li.highcharts-separator {
    height: 15px;
    background-color: transparent;
    width: 36px;
    pointer-events: none;
}

.highcharts-bindings-wrapper
    li.highcharts-separator
    > span.highcharts-menu-item-btn {
    width: 100%;
}

.highcharts-bindings-wrapper
    li.highcharts-active
    > span.highcharts-menu-item-btn,
.highcharts-bindings-wrapper li > span.highcharts-menu-item-btn:hover,
.highcharts-bindings-wrapper .highcharts-arrow-wrapper > div:hover,
.highcharts-bindings-wrapper li.highcharts-active,
.highcharts-toggle-toolbar:hover {
    background-color: #e6ebf5;
    transition: background-color 100ms;
}

.highcharts-toggle-toolbar {
    position: absolute;
    cursor: pointer;
    width: 10px;
    height: 10px;
    background-color: #f7f7f7;
    background-size: cover;
}

.highcharts-hide {
    display: none;
}

.highcharts-bindings-wrapper li:hover,
.highcharts-submenu-item-arrow:hover {
    background-color: #e6ebf5;
}

.highcharts-bindings-wrapper .highcharts-arrow-down,
.highcharts-bindings-wrapper .highcharts-arrow-up {
    width: 50%;
    height: 20px;
    float: left;
}

li.highcharts-disabled-btn:hover,
.highcharts-disabled-btn .highcharts-submenu-item-arrow:hover {
    background-color: #f7f7f7;
}

.highcharts-popup.highcharts-annotation-toolbar {
    right: 10%;
    left: auto;
    height: 40px;
    overflow: hidden;
    padding-right: 40px;
    width: auto;
    min-width: 0;
}

.highcharts-popup.highcharts-annotation-toolbar button {
    margin-top: 0px;
}

.highcharts-popup.highcharts-annotation-toolbar > span {
    display: block;
    float: left;
    padding: 12px;
}

.highcharts-popup {
    /* font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif; */
    background-color: #fff;
    color: #666666;
    display: none;
    font-size: 0.876em;
    max-height: 90%;
    top: 5%;
    left: 15%;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 75%;
    min-width: 300px;
    max-width: 600px;
    position: absolute;
    z-index: 100;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(61, 61, 61, 0.3);
    -moz-box-shadow: 0px 0px 8px 0px rgba(61, 61, 61, 0.3);
    box-shadow: 0px 0px 8px 0px rgba(61, 61, 61, 0.3);
}

.highcharts-popup div,
.highcharts-popup span {
    box-sizing: border-box;
}

.highcharts-popup input,
.highcharts-popup label,
.highcharts-popup select {
    clear: both;
    float: left;
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.highcharts-popup input {
    border: 1px solid #e6e6e6;
    border-radius: 0.3rem;
    padding: 5px;
    width: 100%;
}

.highcharts-popup-lhs-col,
.highcharts-popup-rhs-col {
    height: 100%;
    float: left;
    overflow-y: auto;
}

.highcharts-popup-lhs-col.highcharts-popup-lhs-full {
    width: 100%;
    overflow-x: hidden;
    height: calc(100% - 104px);
    border: none;
    padding: 20px;
    padding-bottom: 10px;
}

.highcharts-popup-lhs-col.highcharts-popup-lhs-full
    + .highcharts-popup-bottom-row {
    width: 100%;
}

.highcharts-popup-lhs-col {
    clear: both;
    width: 30%;
    border-right: 1px solid #e6e6e6;
}

.highcharts-popup-bottom-row {
    float: left;
    padding: 0px 20px;
    padding-bottom: 12px;
    width: 100%;
    border-top: 1px solid #e6e6e6;
}

.highcharts-popup-rhs-col {
    width: 70%;
    height: calc(100% - 40px);
    padding: 20px;
}

.highcharts-popup-rhs-col-wrapper {
    width: 100%;
    overflow-x: hidden;
}

.highcharts-popup-rhs-col-wrapper h3 {
    margin-top: 0px;
    padding-bottom: 0px;
}

.highcharts-bindings-wrapper ul.highcharts-indicator-list,
.highcharts-indicator-list {
    float: left;
    color: #666666;
    height: calc(100% - 150px);
    width: 100%;
    overflow-x: hidden;
    margin: 0px;
    padding: 15px 0px;
}

.highcharts-indicator-list li {
    cursor: pointer;
    padding: 5px 20px;
    margin: 0px;
    width: 100%;
    height: auto;
    overflow: hidden;
    word-break: break-all;
    box-sizing: border-box;
}

.highcharts-indicator-list li:hover {
    background-color: #e6e6e6;
}

.highcharts-tab-item {
    background-color: #f7f7f7;
    cursor: pointer;
    display: block;
    float: left;
    padding: 0px 10px;
    height: 40px;
    line-height: 40px;
}

.highcharts-tab-item.highcharts-tab-item-active {
    background-color: #e6e6e6;
}

.highcharts-tab-item-content {
    display: none;
    float: left;
    height: 100%;
    overflow: hidden;
    width: 100%;
    border-top: 1px solid #e6e6e6;
}

.highcharts-tab-item-show {
    display: block;
}

.highcharts-popup-close {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    padding: 10px;
    top: 0%;
    right: 0%;
    color: #333333;
}

.highcharts-popup-close:hover,
.highcharts-popup button:hover,
.highcharts-popup button.highcharts-annotation-edit-button:hover,
.highcharts-popup button.highcharts-annotation-remove-button:hover {
    background-color: #e6e6e6;
    cursor: pointer;
}

.highcharts-popup button {
    float: right;
    border: none;
    background: #f7f7f7;
    color: #666666;
    margin-left: 5px;
    margin-top: 12px;
}

.highcharts-popup button:first-child {
    margin-left: 0;
}

.highcharts-tab-disabled {
    color: #cccccc;
}

/* annotation edit small popup */
.highcharts-popup button.highcharts-annotation-edit-button,
.highcharts-popup button.highcharts-annotation-remove-button {
    width: 20px;
    height: 40px;
    padding: 20px;
}

.highcharts-popup button.highcharts-annotation-edit-button {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
}

.highcharts-popup button.highcharts-annotation-remove-button {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
}

.highcharts-popup .highcharts-annotation-title {
    display: block;
    float: left;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 15px;
    width: 100%;
}

.highcharts-popup .highcharts-popup-main-title {
    border-bottom: 1px solid #e6e6e6;
    margin: 0px;
    padding: 8px 0px 6px 20px;
}

.highcharts-indicator-title {
    float: left;
    padding-bottom: 15px;
}

.highcharts-input-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.highcharts-input-search-indicators-label {
    text-align: center;
    font-weight: bold;
    color: #000000;
    margin-top: 0.5rem;
}

input.highcharts-input-search-indicators {
    width: 80%;
    margin: 0 auto;
    float: none;
    border-color: #949494;
}

.highcharts-popup a.clear-filter-button {
    margin: 0 auto;
    display: none;
    color: #335cad;
    background-color: #fff;
    border: 1px solid #335cad;
    margin-top: 10px;
}

.highcharts-popup a.clear-filter-button:hover {
    color: #fff;
    background-color: #335cad;
    cursor: pointer;
}
