* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

/* .MuiOutlinedInput-notchedOutline {
    border-width: 0px !important;
} */

/* .MuiFormControl-root-MuiTextField-root {
    width: 100%;
} */

@font-face {
    font-family: "PlusJakartaSans";
    src: url(../fonts/PlusJakartaSans-Bold.woff2) format("woff2");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "PlusJakartaSans";
    src: url(../fonts/PlusJakartaSans-BoldItalic.woff2) format("woff2");
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: "PlusJakartaSans";
    src: url(../fonts/PlusJakartaSans-ExtraBold.woff2) format("woff2");
    font-weight: bolder;
    font-style: normal;
}
@font-face {
    font-family: "PlusJakartaSans";
    src: url(../fonts/PlusJakartaSans-ExtraBoldItalic.woff2) format("woff2");
    font-weight: bolder;
    font-style: italic;
}
@font-face {
    font-family: "PlusJakartaSans";
    src: url(../fonts/PlusJakartaSans-ExtraLight.woff2) format("woff2");
    font-weight: lighter;
    font-style: normal;
}
@font-face {
    font-family: "PlusJakartaSans";
    src: url(../fonts/PlusJakartaSans-ExtraLightItalic.woff2) format("woff2");
    font-weight: lighter;
    font-style: italic;
}
@font-face {
    font-family: "PlusJakartaSans";
    src: url(../fonts/PlusJakartaSans-Italic.woff2) format("woff2");
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: "PlusJakartaSans";
    src: url(../fonts/PlusJakartaSans-Light.woff2) format("woff2");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "PlusJakartaSans";
    src: url(../fonts/PlusJakartaSans-LightItalic.woff2) format("woff2");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: "PlusJakartaSans";
    src: url(../fonts/PlusJakartaSans-Medium.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "PlusJakartaSans";
    src: url(../fonts/PlusJakartaSans-MediumItalic.woff2) format("woff2");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: "PlusJakartaSans";
    src: url(../fonts/PlusJakartaSans-Regular.woff2) format("woff2");
    font-weight: normal;
    font-style: normal;
}

/* LIGHT */
body {
    /* GENERAL */

    /* font-family: "PlusJakartaSans"; */
    line-height: 1.5;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.87);

    --background-color-1: #f5f5f7;
    --background-color-2: #ffffff;
    --background-color-3: #f5f5f7;
    --background-color-4: #ffffff;
    --background-color-5: #e2e2e2;

    /* DASHBOARD */
    /* TOPBAR */
    --background-color-6: #4b5768;
    /* SIDEBAR */
    --background-color-7: #2e3c4e;
    --background-color-8: #3c4c61;
    --background-color-9: #4d617a;
    --background-color-sidesubmenu: #233044;

    --accent-color-1: #e9e9e9;
    --accent-color-2: #e0e0e4;
    --accent-color-3: #d9d9e2;

    --background-color-red: #b00020;
    --background-color-green: green;
    --background-color-blue: #00498e;
    --background-color-light-blue: #3e6a92;
    --background-color-white: #f5f5f7;
    --background-color-black: #0f0f0f;
    --background-color-orange: #faad14;
    --background-color-yellow: rgb(211, 190, 0);
    --background-color-autofill: #bdd5f7;

    --background-color-table-true: #f1f7fd;
    --background-color-table-true-hover: #e3eefa;
    --background-color-table-false: #f7f9fc;
    --background-color-table-false-hover: #f0f0f04d;

    background-color: var(--background-color-1);

    --text-color-1: #0d0d0d;
    --text-color-2: #505050;
    --text-color-3: #f9f9f9;

    --text-color-unactive: #b3b3b3;

    color: var(--text-color-1);

    --text-color-green: green;
    --text-color-red: rgb(207, 0, 0);
    --text-color-yellow: rgb(211, 190, 0);
    --text-color-blue: #004280;
    /* old: --text-color-blue: #00498e; */
    --text-color-white: #f5f5f7;
    --text-color-black: #0f0f0f;

    /* BUTTONS */
    --button-background-color-1: #f9f9f9;
    --button-background-color-2: #00498e;
    --button-background-color-3: var(--background-color-3);
    --button-background-color-4: var(--background-color-4);

    --button-text-color-1: #0d0d0d;
    --button-text-color-2: #f9f9f9;

    --button-border-color-1: black;
    --button-border-color-2: #6e6e6e;

    /* BORDERS */
    --border-color-1: #e5e5e5;
    --border-color-2: #0d0d0d;
    --border-color-3: #6e6e6e;
    --border-color-4: #b8b8b8;

    /* SHADOWS */
    --shadow-horizontal-color-1: rgba(0, 0, 0, 0.2);
    --shadow-vertical-color-1: rgba(0, 0, 0, 0.19);

    /* SCROLLBAR */
    --scrollbar-color-background-1: #f9f9f9;
    --scrollbar-color-thumb-1: rgb(166, 166, 166);

    /* HIGHCHARTS */
    --highcharts-color-blue: rgb(106, 165, 255);
    --highcharts-color-grey: grey;
    --highcharts-color-red: rgb(228, 55, 55);
    --highcharts-color-1: #7cb5ec;
    --highcharts-color-2: #434348;
    --highcharts-color-3: #90ed7d;
    --highcharts-color-4: #f7a35c;
    --highcharts-color-5: #8085e9;
    --highcharts-color-6: #f15c80;
    --highcharts-color-7: #e4d354;
    --highcharts-color-8: #2b908f;
    --highcharts-color-9: #f45b5b;
    --highcharts-color-10: #91e8e1;
}

body.dark {
    /* GENERAL */
    --background-color-1: #1f1f1f;
    --background-color-2: #282828;
    --background-color-3: #333333;
    --background-color-4: #464646;
    --background-color-5: #333333;

    /* DASHBOARD */
    /* TOPBAR */
    --background-color-6: #252525;
    /* SIDEBAR */
    --background-color-7: #333333;
    --background-color-8: #4b4b4b;
    --background-color-9: #575757;
    --background-color-sidesubmenu: #233044;

    --accent-color-1: #414141;
    --accent-color-2: #464646;
    --accent-color-3: #3d3e41;

    --background-color-red: #b00020;
    --background-color-green: rgb(11, 100, 11);
    --background-color-blue: #3176b3;
    --background-color-light-blue: #6c9ac2;
    --background-color-white: #f5f5f7;
    --background-color-black: #0f0f0f;
    --background-color-orange: #bd7e02;
    --background-color-yellow: rgb(211, 190, 0);
    --background-color-autofill: #4b5768;

    --background-color-table-true: #282828;
    --background-color-table-true-hover: #313233;
    --background-color-table-false: #222222;
    --background-color-table-false-hover: #29292b;

    background-color: var(--background-color-1);

    --text-color-1: #f7f7f7;
    --text-color-2: #909090;
    --text-color-3: #f9f9f9;

    --text-color-unactive: #a3a3a3;

    color: var(--text-color-1);

    --text-color-green: green;
    --text-color-red: rgb(207, 0, 0);
    --text-color-yellow: rgb(211, 190, 0);
    --text-color-blue: #3ea5ff;
    /* old: --text-color-blue: #3780be; */
    --text-color-white: #f9f9f9;
    --text-color-black: #0d0d0d;

    /* BUTTONS */
    --button-background-color-1: var(--background-color-2);
    --button-background-color-2: var(--background-color-blue);
    --button-background-color-3: var(--background-color-3);
    --button-background-color-4: var(--background-color-4);

    --button-text-color-1: #f9f9f9;
    --button-text-color-2: #f9f9f9;

    --button-border-color-1: #1f1f1f;
    --button-border-color-2: #979797;

    /* BORDERS */
    --border-color-1: #3e3e3e;
    --border-color-2: #0d0d0d;
    --border-color-3: #979797;
    --border-color-4: #b1b1b1;

    /* SHADOWS */
    --shadow-horizontal-color-1: rgba(0, 0, 0, 0.2);
    --shadow-vertical-color-1: rgba(0, 0, 0, 0.19);

    /* SCROLLBAR */
    --scrollbar-color-background-1: #1f1f1f;
    --scrollbar-color-thumb-1: rgb(166, 166, 166);

    /* HIGHCHARTS */
    --highcharts-color-blue: rgb(106, 165, 255);
    --highcharts-color-grey: grey;
    --highcharts-color-red: rgb(228, 55, 55);
    --highcharts-color-1: #7cb5ec;
    --highcharts-color-2: #909090;
    --highcharts-color-3: #90ed7d;
    --highcharts-color-4: #f7a35c;
    --highcharts-color-5: #8085e9;
    --highcharts-color-6: #f15c80;
    --highcharts-color-7: #e4d354;
    --highcharts-color-8: #2b908f;
    --highcharts-color-9: #f45b5b;
    --highcharts-color-10: #91e8e1;
}

/* INPUT FIELDS */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
    background-color: var(--background-color-autofill) !important;
    color: var(--text-color-1) !important;
    -webkit-box-shadow: 0 0 0 1000px var(--background-color-autofill) inset !important;
    -webkit-text-fill-color: var(--text-color-1) !important;
}

/* ARROWS AT NUMBER INPUTS */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.input_basic {
    border: var(--border-color-4) 1px solid;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
}

.input_basic:hover {
    border: var(--text-color-1) 1px solid;
    opacity: 0.9;
}

.input_basic_border {
    border: var(--text-color-2) 1px solid;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
}

.input_basic_border:hover {
    border: var(--text-color-1) 1px solid;
    opacity: 0.9;
}

.input_text {
    color: var(--text-color-1);
}
.input_text::placeholder {
    color: var(--text-color-1);
}
.input_text:-ms-input-placeholder {
    color: var(--text-color-1);
}
.input_text::-ms-input-placeholder {
    color: var(--text-color-1);
}

/*SIDEMENU DEPENDENCIES*/

.sidemenu_open {
    margin-left: 250px;
    transition: 350ms;
    padding-top: 80px;
    min-height: 100vh;
}

.sidemenu_closed {
    margin-left: 0;
    transition: 350ms;
    padding-top: 80px;
    min-height: 100vh;
}

.sidemenu_width_sidebar_open {
    width: 250px;
}

.sidemenu_width_sidebar_closed {
    width: 60px;
}

.topmenu_width_sidebar_open {
    width: calc(100% - 250px);
}

.topmenu_width_sidebar_closed {
    width: calc(100% - 60px);
}

.topmenu_height {
    height: 80px;
}

.topmenu_height_sidebar {
    height: 83px;
}

.sidesubmenu_height {
    height: calc(100vh - 80px);
}

.sidesubmenu_width {
    width: 250px;
}

.mt_-3px_-7px_-10px {
    margin-top: -3px;
}

.line_height_none {
    line-height: normal;
}

@media screen and (max-width: 1224px) {
    .sidemenu_open {
        margin-left: 0;
        transition: 350ms;
        padding-top: 80px;
        min-height: 100vh;
    }

    .sidemenu_closed {
        margin-left: 0;
        transition: 350ms;
        padding-top: 80px;
        min-height: 100vh;
    }
}

@media screen and (max-width: 568px) {
    .sidemenu_width_sidebar_open {
        width: 250px;
    }

    .sidemenu_width_sidebar_closed {
        width: 60px;
    }

    .topmenu_width_sidebar_open {
        width: calc(100% - 250px);
    }

    .topmenu_width_sidebar_closed {
        width: calc(100% - 60px);
    }

    .topmenu_height {
        height: 70px;
    }

    .topmenu_height_sidebar {
        height: 66px;
    }

    .sidesubmenu_width {
        width: 250px;
    }

    .mt_-3px_-7px_-10px {
        margin-top: -7px;
    }
}

@media screen and (max-width: 320px) {
    .sidemenu_width_sidebar_open {
        width: 100%;
    }

    .sidemenu_width_sidebar_closed {
        width: 60px;
    }

    .topmenu_width_sidebar_open {
        width: 0%;
    }

    .topmenu_width_sidebar_closed {
        width: calc(100% - 60px);
    }

    .topmenu_height {
        height: 60px;
    }

    .topmenu_height_sidebar {
        height: 50px;
    }

    .sidesubmenu_width {
        width: 100vw;
    }

    .mt_-3px_-7px_-10px {
        margin-top: -10px;
    }
}

/*HIGHCHARTS*/
.highcharts_color_1 {
    background-color: var(--highcharts-color-1);
}

.highcharts_color_2 {
    background-color: var(--highcharts-color-2);
}

.highcharts_color_3 {
    background-color: var(--highcharts-color-3);
}

.highcharts_color_4 {
    background-color: var(--highcharts-color-4);
}

.highcharts_color_5 {
    background-color: var(--highcharts-color-5);
}

.highcharts_color_6 {
    background-color: var(--highcharts-color-6);
}

.highcharts_color_7 {
    background-color: var(--highcharts-color-7);
}

.highcharts_color_8 {
    background-color: var(--highcharts-color-8);
}

.highcharts_color_9 {
    background-color: var(--highcharts-color-9);
}

.highcharts_color_10 {
    background-color: var(--highcharts-color-10);
}

.highcharts_blue {
    background-color: var(--highcharts-color-blue);
}

.highcharts_grey {
    background-color: var(--highcharts-color-grey);
}

.highcharts_red {
    background-color: var(--highcharts-color-red);
}

.chart {
    overflow: visible;
}

/* DISPLAY */

.display_none {
    display: none;
}

.display_block {
    display: block;
}

.mobile_display_none {
    display: block;
}

.mobile_display_block {
    display: block;
}

.display_320px {
    display: block;
}

.display_lt320px {
    display: none;
}

.display_768px {
    display: block;
}

.display_lt768px {
    display: none;
}

.display_1100px {
    display: block;
}

@media screen and (max-width: 1100px) {
    .display_1100px {
        display: none;
    }
}
@media screen and (max-width: 768px) {
    .display_768px {
        display: none;
    }

    .display_lt768px {
        display: block;
    }
}

@media screen and (max-width: 568px) {
    .mobile_display_none {
        display: none;
    }
}

@media screen and (max-width: 320px) {
    .display_320px {
        display: none;
    }

    .display_lt320px {
        display: block;
    }
}

/*FLEX*/
.flex {
    display: flex;
    align-items: center;
}

.align_items_start {
    align-items: flex-start;
}

.align_items_end {
    align-items: flex-end;
}

.align_self_end {
    align-self: flex-end;
}

.flex_column {
    flex-direction: column;
}

.flex_column_reverse {
    flex-direction: column-reverse;
}

.flex_row {
    flex-direction: row;
}

.flex_row_reverse {
    flex-direction: row-reverse;
}

.wrap {
    flex-wrap: wrap;
}

.no_wrap {
    flex-wrap: nowrap;
}

.left {
    justify-content: left;
}

.left_center {
    justify-content: left;
}

.right {
    justify-content: right;
}

.right_left {
    justify-content: right;
}

.start {
    justify-content: flex-start;
}

.end {
    justify-content: flex-end;
}

.center {
    justify-content: center;
}

.space_between {
    justify-content: space-between;
}

.space_around {
    justify-content: space-around;
}

@media screen and (max-width: 768px) {
    .right_left {
        justify-content: left;
    }
}

@media screen and (max-width: 568px) {
    .left_center {
        justify-content: center;
    }

    .pl_20px_0px {
        padding-left: 0px;
    }

    .pr_20px_0px {
        padding-left: 0px;
    }
}

/* GRID */

.grid {
    display: grid;
}

.grid_column_repeat_5_4_3_2_1 {
    grid-template-columns: repeat(5, 1fr);
}

@media screen and (max-width: 1950px) {
    .grid_column_repeat_5_4_3_2_1 {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 1650px) {
    .grid_column_repeat_5_4_3_2_1 {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 1150px) {
    .grid_column_repeat_5_4_3_2_1 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 700px) {
    .grid_column_repeat_5_4_3_2_1 {
        grid-template-columns: repeat(1, 1fr);
    }
}

/*POSITION*/

.pos_absolute {
    position: absolute;
}

.pos_fixed {
    position: fixed;
}

.pos_sticky {
    position: sticky;
    position: -webkit-sticky; /* for Safari */
}

.pos_relative {
    position: relative;
}

.pos_bottom {
    bottom: 0;
}

.pos_top {
    top: 0;
}

.pos_below_topbar {
    top: 80px;
}

.pos_right {
    right: 0;
}

.pos_right_10 {
    right: 10px;
}

.pos_right_20 {
    right: 20px;
}

.pos_left {
    left: 0;
}

.pos_left_-100 {
    left: -100%;
}

@media screen and (max-width: 568px) {
    .pos_below_topbar {
        top: 70px;
    }
}

@media screen and (max-width: 320px) {
    .pos_below_topbar {
        top: 60px;
    }
}

.float_left {
    float: left;
}

.float_right {
    float: right;
}

/*BOX SIZING*/

.border_box {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
}

.content_box {
    box-sizing: content-box;
}

/*Z INDEX*/

.z_index_5 {
    z-index: 5;
}

.z_index_10 {
    z-index: 10;
}

.z_index_19 {
    z-index: 19;
}

.z_index_20 {
    z-index: 20;
}

.z_index_50 {
    z-index: 50;
}

.z_index_100 {
    z-index: 100;
}

.z_index_max {
    z-index: 10000000000;
}

/*TEXT*/

.text_color_transparent {
    color: rgb(0, 0, 0, 0);
}

.text_color_1 {
    color: var(--text-color-1);
}

.text_color_2 {
    color: var(--text-color-2);
}

/* .color_grey_2 {
    color: rgb(145, 145, 145);
} */

.text_color_3 {
    color: var(--text-color-3);
}

.text_color_unactive {
    color: var(--text-color-unactive);
}

.text_color_green {
    color: var(--text-color-green);
}

.text_color_blue {
    color: var(--text-color-blue);
}

.text_color_red {
    color: var(--text-color-red);
}

.text_color_yellow {
    color: var(--text-color-yellow);
}

.text_color_white {
    color: var(--text-color-white);
}

.text_color_black {
    color: var(--text-color-black);
}

.text_left {
    text-align: start;
}

.text_right {
    text-align: end;
}

.text_center {
    text-align: center;
}

.text_top {
    vertical-align: text-top;
}

.text_block {
    text-align: justify;
}

.title_1 {
    font-size: 51px;
}

.title_2 {
    font-size: 40px;
}

.title_3 {
    font-size: 31px;
}

.title_4 {
    font-size: 23px;
}

.title_5 {
    font-size: 20px;
}

.title_6 {
    font-size: 18px;
}

.title_7 {
    font-size: 16px;
}

.text_1 {
    font-size: 19px;
}

.text_2 {
    font-size: 14px;
}

.text_3 {
    font-size: 13px;
}

.text_4 {
    font-size: 12px;
}

.text_5 {
    font-size: 10px;
}

.text_6 {
    font-size: 8px;
}

.text_weight_1 {
    font-weight: 100;
}

.text_weight_2 {
    font-weight: 200;
}

.text_weight_3 {
    font-weight: 300;
}

.text_weight_4 {
    font-weight: 400;
}

.text_weight_5 {
    font-weight: 400;
}

.text_weight_6 {
    font-weight: 400;
}

.text_decoration_none {
    text-decoration: none;
}

.text_decoration_underline {
    text-decoration: underline;
}

.resize_none {
    resize: none;
}

.text_truncated {
    display: block;
    white-space: nowrap; /* forces text to single line */
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 768px) {
    .title_1 {
        font-size: 40px;
    }

    .title_2 {
        font-size: 31px;
    }

    .title_3 {
        font-size: 23px;
    }

    .title_4 {
        font-size: 20px;
    }

    .title_5 {
        font-size: 18px;
    }

    .title_6 {
        font-size: 16px;
    }

    .title_7 {
        font-size: 15px;
    }

    .text_1 {
        font-size: 16px;
    }

    .text_2 {
        font-size: 14px;
    }

    .text_3 {
        font-size: 12px;
    }

    .text_4 {
        font-size: 10px;
    }

    .text_5 {
        font-size: 8px;
    }

    .text_6 {
        font-size: 6px;
    }
}

@media screen and (max-width: 320px) {
    .title_1 {
        font-size: 34px;
    }

    .title_2 {
        font-size: 26px;
    }

    .title_3 {
        font-size: 22px;
    }

    .title_4 {
        font-size: 20px;
    }

    .title_5 {
        font-size: 18px;
    }

    .title_6 {
        font-size: 15px;
    }

    .text_1 {
        font-size: 15px;
    }

    .text_2 {
        font-size: 13px;
    }

    .text_3 {
        font-size: 11px;
    }

    .text_4 {
        font-size: 9px;
    }

    .text_5 {
        font-size: 7px;
    }

    .text_6 {
        font-size: 5px;
    }
}

/*BACKGROUND*/

.background_color_transparent {
    background-color: rgb(0, 0, 0, 0);
}

.background_color_1 {
    background-color: var(--background-color-1);
}

.background_color_1_hover_background_color_3 {
    background-color: var(--background-color-1);
}

.background_color_1_hover_background_color_3:hover {
    background-color: var(--background-color-3);
}

.background_color_2 {
    background-color: var(--background-color-2);
}

.background_color_2_important {
    background-color: var(--background-color-2) !important;
}

.background_color_2_hover_background_color_3 {
    background-color: var(--background-color-2);
}

.background_color_2_hover_background_color_3:hover {
    background-color: var(--background-color-3);
}

.background_color_3 {
    background-color: var(--background-color-3);
}

.background_color_3_hover_background_color_4 {
    background-color: var(--background-color-3);
}

.background_color_3_hover_background_color_4:hover {
    background-color: var(--background-color-4);
}

.background_color_4 {
    background-color: var(--background-color-4) !important;
}

.background_color_5 {
    background-color: var(--background-color-5);
}

.background_color_6 {
    background-color: var(--background-color-6);
}

.background_color_7 {
    background-color: var(--background-color-7);
}

.background_color_8 {
    background-color: var(--background-color-8);
}

.background_color_9 {
    background-color: var(--background-color-9);
}

.background_color_sidesubmenu {
    background-color: var(--background-color-sidesubmenu);
}

.background_color_red {
    background-color: var(--background-color-red);
}

.background_color_yellow {
    background-color: var(--background-color-yellow);
}

.background_color_green {
    background-color: var(--background-color-green);
}

.background_color_blue {
    background-color: var(--background-color-blue);
}

.hover_background_color_blue:hover {
    background-color: var(--background-color-blue);
}

.background_color_light_blue {
    background-color: var(--background-color-light-blue);
}

.background_color_white {
    background-color: var(--background-color-white);
}

.background_color_black {
    background-color: var(--background-color-black);
}

.background_color_transparent {
    background-color: rgb(0, 0, 0, 0.6);
}

.background_color_gradient_red_yellow_green {
    background: linear-gradient(
        90deg,
        var(--background-color-red) 0%,
        var(--background-color-yellow) 49%,
        var(--background-color-green) 100%
    );
}

.accent_color_1 {
    background-color: var(--accent-color-1);
}

.accent_color_2 {
    background-color: var(--accent-color-2);
}

.accent_color_3 {
    background-color: var(--accent-color-3);
}

.background_color_table_true {
    background-color: #f1f7fd;
}

.background_color_table:hover {
    background-color: #e3eefa;
}

.background_color_table_false {
    background-color: #f7f9fc;
}

.background_color_table_false:hover {
    background-color: #f0f0f04d;
}

/* MARGIN */

.margin_auto {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.ms_auto {
    margin-left: auto;
    margin-right: auto;
}

.mv_auto {
    margin-top: auto;
    margin-bottom: auto;
}

.mt_auto {
    margin-top: auto;
}

.margin_0 {
    margin: 0 !important;
}

.margin_5px {
    margin: 5px !important;
}

.margin_6px {
    margin: 6px !important;
}

.margin_10px {
    margin: 10px !important;
}

.margin_15px {
    margin: 15px !important;
}

.margin_20px {
    margin: 20px !important;
}

.ml_-22px {
    margin-left: -22px;
}
.mr_-22px {
    margin-right: -22px;
}
.mt_-22px {
    margin-top: -22px;
}
.mb_-22px {
    margin-bottom: -22px;
}

.ml_0px {
    margin-left: 0px;
}
.mr_0px {
    margin-right: 0px;
}
.mt_0px {
    margin-top: 0px;
}
.mb_0px {
    margin-bottom: 0px;
}

.ml_2px {
    margin-left: 2px;
}
.mr_2px {
    margin-right: 2px;
}
.mt_2px {
    margin-top: 2px;
}
.mb_2px {
    margin-bottom: 2px;
}
.mt_-2px {
    margin-top: -2px;
}
.mb_-2px {
    margin-bottom: -2px;
}

.ml_3px {
    margin-left: 3px;
}
.mr_3px {
    margin-right: 3px;
}
.mt_3px {
    margin-top: 3px;
}
.mb_3px {
    margin-bottom: 3px;
}
.mt_-3px {
    margin-top: -3px;
}
.mb_-3px {
    margin-bottom: -3px;
}

.ml_4px {
    margin-left: 4px;
}
.mr_4px {
    margin-right: 4px;
}
.mt_4px {
    margin-top: 4px;
}
.mb_4px {
    margin-bottom: 4px;
}

.ml_5px {
    margin-left: 5px;
}
.mr_5px {
    margin-right: 5px;
}
.mt_5px {
    margin-top: 5px;
}
.mb_5px {
    margin-bottom: 5px;
}
.mt_-5px {
    margin-top: -5px;
}
.mb_-5px {
    margin-bottom: -5px;
}

.ml_6px {
    margin-left: 6px;
}
.mr_6px {
    margin-right: 6px;
}
.mt_6px {
    margin-top: 6px;
}
.mb_6px {
    margin-bottom: 6px;
}

.ml_8px {
    margin-left: 8px;
}
.mr_8px {
    margin-right: 8px;
}
.mt_8px {
    margin-top: 8px;
}
.mb_8px {
    margin-bottom: 8px;
}

.ml_10px {
    margin-left: 10px;
}
.mr_10px {
    margin-right: 10px;
}
.mt_10px {
    margin-top: 10px;
}
.mb_10px {
    margin-bottom: 10px;
}
.mt_-10px {
    margin-top: -10px;
}
.ms_10px {
    margin-left: 10px;
    margin-right: 10px;
}

.ml_10px_0px {
    margin-left: 10px;
}
.mr_10px_0px {
    margin-right: 10px;
}

.ml_13px {
    margin-left: 13px;
}
.mr_13px {
    margin-right: 13px;
}
.mt_13px {
    margin-top: 13px;
}
.mb_13px {
    margin-bottom: 13px;
}

.ml_15px {
    margin-left: 15px;
}
.mr_15px {
    margin-right: 15px;
}
.mt_15px {
    margin-top: 15px;
}
.mb_15px {
    margin-bottom: 15px;
}
.mt_-15px {
    margin-top: -15px;
}

.ml_16px {
    margin-left: 16px;
}

.ml_20px {
    margin-left: 20px;
}
.mr_20px {
    margin-right: 20px;
}
.mt_20px {
    margin-top: 20px;
}
.mb_20px {
    margin-bottom: 20px;
}
.mt_-20px {
    margin-top: -20px;
}
.ml_20px_0px {
    margin-left: 20px;
}
.mr_20px_0px {
    margin-right: 20px;
}
.ms_20px {
    margin-left: 20px;
    margin-right: 20px;
}

.ml_22px {
    margin-left: 22px;
}
.mr_22px {
    margin-right: 22px;
}
.mt_22px {
    margin-top: 22px;
}
.mb_22px {
    margin-bottom: 22px;
}

.mt_-23px {
    margin-top: -23px;
}

.ml_25px {
    margin-left: 25px;
}
.mr_25px {
    margin-right: 25px;
}
.mt_25px {
    margin-top: 25px;
}
.mb_25px {
    margin-bottom: 25px;
}
.mt_-25px {
    margin-top: -25px;
}
.ml_25px_10px {
    margin-left: 25px;
}
.mr_25px_10px {
    margin-right: 25px;
}

.ml_29px {
    margin-left: 29px;
}
.mr_29px {
    margin-right: 29px;
}
.mt_29px {
    margin-top: 29px;
}
.mb_29px {
    margin-bottom: 29px;
}

.ml_30px {
    margin-left: 30px;
}
.mr_30px {
    margin-right: 30px;
}
.mt_30px {
    margin-top: 30px;
}
.mb_30px {
    margin-bottom: 30px;
}
.ms_30px {
    margin-left: 30px;
    margin-right: 30px;
}
.mt_-30px {
    margin-top: -30px;
}
.mb_-30px {
    margin-bottom: -30px;
}

.ml_35px {
    margin-left: 35px;
}
.mr_35px {
    margin-right: 35px;
}
.mt_35px {
    margin-top: 35px;
}
.mb_35px {
    margin-bottom: 35px;
}
.mt_-35px {
    margin-top: -35px;
}
.mb_-35px {
    margin-bottom: -35px;
}

.ml_40px {
    margin-left: 40px;
}
.mr_40px {
    margin-right: 40px;
}
.mt_40px {
    margin-top: 40px;
}
.mb_40px {
    margin-bottom: 40px;
}
.mt_-40px {
    margin-top: -40px;
}
.mb_-40px {
    margin-bottom: -40px;
}

.ml_50px {
    margin-left: 50px;
}
.mr_50px {
    margin-right: 50px;
}
.mt_50px {
    margin-top: 50px;
}
.mb_50px {
    margin-bottom: 50px;
}
.mt_-50px {
    margin-top: -50px;
}

.ml_60px {
    margin-left: 60px;
}
.mr_60px {
    margin-right: 60px;
}
.mt_60px {
    margin-top: 60px;
}
.mb_60px {
    margin-bottom: 60px;
}
.mt_-60px {
    margin-top: -60px;
}
.ms_60px {
    margin-left: 60px;
    margin-right: 60px;
}

.mt_-70px {
    margin-top: -70px;
}

.ml_100px {
    margin-left: 100px;
}
.mr_100px {
    margin-right: 100px;
}
.mt_100px {
    margin-top: 100px;
}
.mb_100px {
    margin-bottom: 100px;
}

.ml_120px {
    margin-left: 120px;
}
.mr_120px {
    margin-right: 120px;
}
.mt_120px {
    margin-top: 120px;
}
.mb_120px {
    margin-bottom: 120px;
}

.ml_250px {
    margin-left: 250px;
}
.mr_250px {
    margin-right: 250px;
}
.mt_250px {
    margin-top: 250px;
}
.mb_250px {
    margin-bottom: 250px;
}

.ml_10pr {
    margin-left: 10%;
}
.mr_10pr {
    margin-right: 10%;
}
.mt_10pr {
    margin-top: 10%;
}
.mb_10pr {
    margin-bottom: 10%;
}

.ml_3pr {
    margin-left: 3%;
}
.mr_3pr {
    margin-right: 3%;
}
.mt_3pr {
    margin-top: 3%;
}
.mb_3pr {
    margin-bottom: 3%;
}

.ml_50pr {
    margin-left: 50%;
}
.mr_50pr {
    margin-right: 50%;
}
.mt_50pr {
    margin-top: 50%;
}
.mb_50pr {
    margin-bottom: 50%;
}

.ml_60pr {
    margin-left: 60%;
}
.mr_60pr {
    margin-right: 60%;
}
.mt_60pr {
    margin-top: 60%;
}
.mb_60pr {
    margin-bottom: 60%;
}

@media screen and (max-width: 768px) {
    .ml_10px_0px {
        margin-left: 0;
    }
    .mr_10px_0px {
        margin-right: 0;
    }

    .ml_20px_0px {
        margin-left: 0px;
    }
    .mr_20px_0px {
        margin-right: 0px;
    }

    .ml_25px_10px {
        margin-left: 10px;
    }
    .mr_25px_10px {
        margin-right: 10px;
    }
}

/*PADDING*/

.padding_auto {
    padding: auto;
}

.padding_0px {
    padding: 0px !important;
}

.padding_3px {
    padding: 3px !important;
}

.padding_4px {
    padding: 4px !important;
}

.padding_5px {
    padding: 5px !important;
}

.pv_5px {
    padding-top: 5px;
    padding-bottom: 5px;
}

.padding_6px {
    padding: 6px !important;
}

.padding_7px {
    padding: 7px !important;
}

.padding_8px {
    padding: 8px !important;
}

.padding_9px {
    padding: 9px !important;
}

.padding_10px {
    padding: 10px !important;
}

.padding_12px_8px {
    padding: 12px !important;
}

.padding_15px {
    padding: 15px !important;
}

.padding_15_0px {
    padding: 15px 0px !important;
}

.padding_15_5px {
    padding: 15px 5px !important;
}

.padding_20px {
    padding: 20px;
}

.pv_10px {
    padding-top: 10px;
    padding-bottom: 10px;
}

.pv_12px_4px {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.pv_12px_8px {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.ps_auto {
    padding-left: auto;
    padding-right: auto;
}

.ps_5px {
    padding-left: 5px;
    padding-right: 5px;
}

.ps_10px {
    padding-left: 10px;
    padding-right: 10px;
}

.ps_20px {
    padding-left: 20px;
    padding-right: 20px;
}

.ps_30px {
    padding-left: 30px;
    padding-right: 30px;
}

.pb_0px {
    padding-bottom: 0px;
}
.pt_0px {
    padding-top: 0px;
}
.pl_0px {
    padding-left: 0px;
}
.pr_0px {
    padding-right: 0px;
}

.pb_3px {
    padding-bottom: 3px;
}
.pt_3px {
    padding-top: 3px;
}
.pl_3px {
    padding-left: 3px;
}
.pr_3px {
    padding-right: 3px;
}

.pb_4px {
    padding-bottom: 4px;
}
.pt_4px {
    padding-top: 4px;
}
.pl_4px {
    padding-left: 4px;
}
.pr_4px {
    padding-right: 4px;
}

.pb_5px {
    padding-bottom: 5px;
}
.pt_5px {
    padding-top: 5px;
}
.pl_5px {
    padding-left: 5px;
}
.pr_5px {
    padding-right: 5px;
}
.pt_-5px {
    padding-top: -5px;
}

.pb_6px {
    padding-bottom: 6px;
}
.pt_6px {
    padding-top: 6px;
}
.pl_6px {
    padding-left: 6px;
}
.pr_6px {
    padding-right: 6px;
}

.pb_7px {
    padding-bottom: 7px;
}
.pt_7px {
    padding-top: 7px;
}
.pl_7px {
    padding-left: 7px;
}
.pr_7px {
    padding-right: 7px;
}

.pb_8px {
    padding-bottom: 8px;
}
.pt_8px {
    padding-top: 8px;
}
.pl_8px {
    padding-left: 8px;
}
.pr_8px {
    padding-right: 8px;
}

.pb_9px {
    padding-bottom: 9px;
}
.pt_9px {
    padding-top: 9px;
}
.pl_9px {
    padding-left: 9px;
}
.pr_9px {
    padding-right: 9px;
}

.pb_10px {
    padding-bottom: 10px;
}
.pt_10px {
    padding-top: 10px;
}
.pl_10px {
    padding-left: 10px;
}
.pr_10px {
    padding-right: 10px;
}

.pb_15px {
    padding-bottom: 15px;
}
.pt_15px {
    padding-top: 15px;
}
.pl_15px {
    padding-left: 15px;
}
.pr_15px {
    padding-right: 15px;
}

.pl_18px {
    padding-left: 18px;
}

.pb_20px {
    padding-bottom: 20px;
}
.pt_20px {
    padding-top: 20px;
}
.pl_20px {
    padding-left: 20px;
}
.pr_20px {
    padding-right: 20px;
}
.pl_20px_0px {
    padding-left: 20px;
}
.pr_20px_0px {
    padding-right: 20px;
}

.pb_22px {
    padding-bottom: 22px;
}
.pt_22px {
    padding-top: 22px;
}
.pl_22px {
    padding-left: 22px;
}
.pr_22px {
    padding-right: 22px;
}

.pb_25px {
    padding-bottom: 25px;
}
.pt_25px {
    padding-top: 25px;
}
.pl_25px {
    padding-left: 25px;
}
.pr_25px {
    padding-right: 25px;
}
.pl_25px_10px {
    padding-left: 25px;
}

.pb_30px {
    padding-bottom: 30px;
}
.pt_30px {
    padding-top: 30px;
}
.pl_30px {
    padding-left: 30px;
}
.pr_30px {
    padding-right: 30px;
}

.pb_35px {
    padding-bottom: 35px;
}
.pt_35px {
    padding-top: 35px;
}
.pl_35px {
    padding-left: 35px;
}
.pr_35px {
    padding-right: 35px;
}

.pb_40px {
    padding-bottom: 40px;
}
.pt_40px {
    padding-top: 40px;
}
.pl_40px {
    padding-left: 40px;
}
.pr_40px {
    padding-right: 40px;
}

.pb_45px {
    padding-bottom: 45px;
}
.pt_45px {
    padding-top: 45px;
}
.pl_45px {
    padding-left: 45px;
}
.pr_45px {
    padding-right: 45px;
}

.pb_50px {
    padding-bottom: 50px;
}
.pt_50px {
    padding-top: 50px;
}
.pl_50px {
    padding-left: 50px;
}
.pr_50px {
    padding-right: 50px;
}

.pb_60px {
    padding-bottom: 60px;
}
.pt_60px {
    padding-top: 60px;
}
.pl_60px {
    padding-left: 60px;
}
.pr_60px {
    padding-right: 60px;
}

.pb_80px {
    padding-bottom: 80px;
}
.pt_80px {
    padding-top: 80px;
}
.pl_80px {
    padding-left: 80px;
}
.pr_80px {
    padding-right: 80px;
}
.mt_80px_70px_60px {
    padding-top: 80px;
}

.pb_100px {
    padding-bottom: 100px;
}
.pt_100px {
    padding-top: 100px;
}
.pl_100px {
    padding-left: 100px;
}
.pr_100px {
    padding-right: 100px;
}

.pb_250px {
    padding-bottom: 250px;
}
.pt_250px {
    padding-top: 250px;
}
.pl_250px {
    padding-left: 250px;
}
.pr_250px {
    padding-right: 250px;
}

@media screen and (max-width: 768px) {
    .pv_12px_4px {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }

    .pv_12px_8px {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
    .padding_12px_8px {
        padding: 8px !important;
    }

    .pl_25px_10px {
        padding-left: 10px;
    }
}

@media screen and (max-width: 568px) {
    .mt_80px_70px_60px {
        padding-top: 70px !important;
    }
}

@media screen and (max-width: 320px) {
    .mt_80px_70px_60px {
        padding-top: 60px !important;
    }
}

/*BUTTON*/

.button_ {
    height: 32px;
    /* border: 1px solid var(--button-border-color-2); */
    border-radius: 5px;
    /* background-color: var(--button-background-color-2); */
    /* color: var(--button-text-color-2); */
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.button_blue {
    height: 32px;
    border: 1px solid var(--button-border-color-2);
    border-radius: 5px;
    background-color: var(--button-background-color-2);
    color: var(--button-text-color-2);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
}

.button_blue:hover {
    opacity: 0.8;
}

.button_blue:active {
    box-shadow: 0 4px 8px 0 var(--shadow-vertical-color-1),
        0 6px 20px 0 var(--shadow-horizontal-color-1);
    transition: transform 0.1s ease-out;
    transform: translateY(3px);
}

.button_blue_small {
    border: 1px solid var(--button-border-color-2);
    border-radius: 3px;
    background-color: var(--button-background-color-2);
    color: var(--button-text-color-2);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
}

.button_blue_small:hover {
    opacity: 0.8;
}

.button_blue_small:active {
    box-shadow: 0 4px 8px 0 var(--shadow-vertical-color-1),
        0 6px 20px 0 var(--shadow-horizontal-color-1);
    transition: transform 0.1s ease-out;
    transform: translateY(3px);
}

.button_grey {
    height: 32px;
    border: 1px solid var(--button-border-color-2);
    border-radius: 5px;
    background-color: var(--background-color-4);
    color: var(--button-text-color-1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
}

.button_grey:hover {
    opacity: 0.8;
}

.button_grey:active {
    box-shadow: 0 4px 8px 0 var(--shadow-vertical-color-1),
        0 6px 20px 0 var(--shadow-horizontal-color-1);
    transition: transform 0.1s ease-out;
    transform: translateY(3px);
}

.button_green {
    height: 32px;
    border: 1px solid var(--button-border-color-2);
    border-radius: 5px;
    background-color: var(--background-color-4);
    color: var(--button-text-color-2);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.button_white {
    box-sizing: border-box;
    height: 32px;
    border: 1px solid var(--button-white_active-border-color-1);
    border-radius: 5px;
    background-color: var(--button-background-color-1);
    color: var(--button-text-color-1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.button_white_active {
    box-sizing: border-box;
    height: 32px;
    border: 1px solid var(--button-background-color-2);
    border-radius: 5px;
    background-color: var(--button-background-color-1);
    color: var(--button-text-color-1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.button_white_2 {
    height: 32px;
    border: 1px solid var(--button-border-color-1);
    background-color: var(--button-background-color-1);
    color: var(--button-text-color-1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.button_white_layer_2 {
    height: 32px;
    border: 1px solid var(--button-border-color-1);
    border-radius: 5px;
    background-color: var(--button-background-color-4);
    color: var(--button-text-color-1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.button_white_2_layer_2 {
    height: 32px;
    border: 1px solid var(--button-border-color-1);
    background-color: var(--button-background-color-3);
    color: var(--button-text-color-1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.button_white_small {
    border-radius: 3px;
    background-color: var(--button-background-color-4);
    color: var(--button-text-color-1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.button_white_layer_2_small {
    border-radius: 3px;
    background-color: var(--button-background-color-3);
    color: var(--button-text-color-1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.button_delete {
    height: 32px;
    border: 1px solid var(--background-color-red);
    border-radius: 5px;
    background-color: var(--button-background-color-1);
    color: var(--button-text-color-1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.button_google {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #cf4332;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.button_facebook {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #3c66c4;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.button_apple {
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.button_text_color_1 {
    color: var(--button-text-color-1);
}

.button_text_color_2 {
    color: var(--button-text-color-2);
}

.button_background_color_1 {
    background-color: var(--button-background-color-1);
}

.button_background_color_2 {
    background-color: var(--button-background-color-2);
}

.button_background_color_3 {
    background-color: var(--button-background-color-3);
}

.button_background_color_4 {
    background-color: var(--button-background-color-4);
}

.button_border_color_1 {
    border: 1px solid var(--button-border-color-1);
}

.button_border_color_2 {
    border: 1px solid var(--button-border-color-2);
}

@media screen and (max-width: 768px) {
    .button_ {
        height: 28px;
    }
    .button_blue {
        height: 28px;
    }
    .button_green {
        height: 28px;
    }
    .button_white {
        height: 28px;
    }
    .button_white_active {
        height: 28px;
    }
    .button_white_2 {
        height: 28px;
    }
    .button_white_layer_2 {
        height: 28px;
    }
    .button_white_2_layer_2 {
        height: 28px;
    }
    .button_delete {
        height: 28px;
    }
}

@media screen and (max-width: 320px) {
}

/*WIDTH*/

.width_auto {
    width: auto;
}

.width_auto_100pr {
    width: auto;
}

.width_auto__100pr {
    width: auto;
}

.width_0px {
    width: 0px;
}

.width_5px {
    width: 5px;
}

.width_10px {
    width: 10px;
}

.width_15px {
    width: 15px;
}

.width_20px {
    width: 20px;
}

.width_30px {
    width: 30px;
}

.width_30px_20px {
    width: 30px;
}

.width_30px_100pr {
    width: 30px;
}

.width_35px {
    width: 35px;
}

.width_40px {
    width: 40px;
}

.min_width_40px {
    min-width: 40px;
}

.width_50px {
    width: 50px;
}

.width_60px {
    width: 60px;
}

.min_width_60px {
    min-width: 60px;
}

.width_80px {
    width: 80px;
}

.min_width_80px {
    min-width: 80px;
}

.width_85px {
    width: 85px;
}

.min_width_100px {
    min-width: 100px;
}

.width_100px {
    width: 100px;
}

.max_width_100px {
    max-width: 100px;
}

.width_110px {
    width: 110px;
}

.min_width_120px {
    min-width: 120px;
}

.width_120px {
    width: 120px;
}

.max_width_120px {
    max-width: 120px;
}

.width_130px {
    width: 130px;
}

.max_width_135px {
    max-width: 135px;
}

.min_width_140px {
    min-width: 140px;
}

.width_140px {
    width: 140px;
}

.width_150px {
    width: 150px;
}

.min_width_150px {
    min-width: 150px;
}

.width_155px {
    width: 155px;
}

.width_160px {
    width: 160px;
}

.width_170px {
    width: 170px;
}

.min_width_180px {
    min-width: 180px;
}

.width_180px {
    width: 180px;
}

.max_width_180px {
    max-width: 180px;
}

.width_200px_120px {
    width: 200px;
}

.min_width_200px {
    min-width: 200px;
}

.min_width_200px_100pr {
    min-width: 200px;
}

.max_width_200px {
    max-width: 200px;
}

.width_200px {
    width: 200px;
}

.width_210px {
    width: 210px;
}

.width_220px {
    width: 220px;
}

.width_220px_100pr {
    width: 220px;
}

.width_230px {
    width: 230px;
}

.width_240px {
    width: 240px;
}

.min_width_250px {
    min-width: 250px;
}

.max_width_250px {
    max-width: 250px;
}

.width_250px {
    width: 250px;
}

.width_280px {
    width: 280px;
}

.min_width_300px {
    min-width: 300px;
}

.width_300px {
    width: 300px;
}

.width_300px_100pr {
    width: 300px;
}

.min_width_320px {
    min-width: 320px;
}

.max_width_239px {
    max-width: 239px;
}

.width_350px {
    width: 350px;
}

.min_width_350px {
    min-width: 350px;
}

.max_width_350px {
    max-width: 350px;
}

.min_width_400px {
    min-width: 400px;
}

.width_400px {
    width: 400px;
}

.max_width_420px {
    max-width: 420px;
}

.min_width_450px {
    min-width: 450px;
}

.width_500px {
    width: 500px;
}

.width_500px_320px {
    width: 500px;
}

.min_width_500px {
    min-width: 500px;
}

.min_width_500px_320px {
    min-width: 500px;
}

.max_width_500px {
    max-width: 500px;
}

.max_width_600px {
    max-width: 600px;
}

.max_width_680px {
    max-width: 680px;
}

.width_700px {
    width: 700px;
}

.width_700px_320px {
    width: 700px;
}

.width_700px_250px_150px {
    width: 700px;
}

.max_width_700px {
    max-width: 700px;
}

.max_width_800px {
    max-width: 800px;
}

.max_width_900px {
    max-width: 900px;
}

.min_width_960px {
    min-width: 960px;
}

.max_width_960px {
    max-width: 960px;
}

.width_1000px {
    width: 1000px;
}

.max_width_1000px {
    max-width: 1000px;
}

.width_1200px {
    width: 1200px;
}

.max_width_1200px {
    max-width: 1200px;
}

.max_width_1250px {
    max-width: 1250px;
}

.max_width_1500px {
    max-width: 1500px;
}

.max_width_1600px {
    max-width: 1600px;
}

.max_width_1800px {
    max-width: 1800px;
}

.width_5pr {
    width: 5%;
}

.width_10pr {
    width: 10%;
}

.width_12_5pr {
    width: 12.5%;
}

.width_15pr {
    width: 15%;
}

.width_20pr {
    width: 20%;
}

.width_22pr {
    width: 22%;
}

.width_24pr {
    width: 24%;
}

.width_25pr {
    width: 25%;
}

.width_26pr {
    width: 26%;
}

.width_28pr {
    width: 28%;
}

.width_30pr {
    width: 30%;
}

.width_30pr_50pr {
    width: 30%;
}

.width_30pr_100pr {
    width: 30%;
}

.width_30pr_45pr_80pr {
    width: 30%;
}

.width_30pr_47pr_95pr {
    width: 30%;
}

.width_30pr_50pr_100pr {
    width: 30%;
}

.width_33pr {
    width: 33.33%;
    width: calc(100% / 3);
}

.width_33pr_50pr_100pr {
    width: 33.33%;
    width: calc(100% / 3);
}

.width_35pr {
    width: 35%;
}

.width_40pr {
    width: 40%;
}

.width_40pr_100pr {
    width: 40%;
}

.width_43pr {
    width: 43%;
}

.width_44pr {
    width: 44%;
}

.width_45pr {
    width: 45%;
}

.width_45pr_95pr {
    width: 45%;
}

.width_48pr {
    width: 48%;
}

.width_48pr_95pr {
    width: 45%;
}

.width_49pr {
    width: 49%;
}

.width_50pr {
    width: 50%;
}

.width_50pr_30pr {
    width: 50%;
}

.width_50pr_100pr {
    width: 50%;
}

.width_51pr {
    width: 51%;
}

.width_52pr {
    width: 52%;
}

.width_54pr {
    width: 54%;
}

.width_55pr {
    width: 55%;
}

.width_56pr {
    width: 56%;
}

.width_58pr {
    width: 58%;
}

.width_60pr {
    width: 60%;
}

.width_60pr_40pr_150px {
    width: 60%;
}

.width_60pr_100pr {
    width: 60%;
}

.width_65pr {
    width: 65%;
}

.width_70pr_60pr {
    width: 70%;
}

.width_70pr {
    width: 70%;
}

.width_70pr_90pr {
    width: 70%;
}

.width_70pr_95pr {
    width: 70%;
}

.width_75pr {
    width: 75%;
}

.width_80pr_60pr {
    width: 80%;
}

.width_80pr {
    width: 80%;
}

.width_80pr_95pr {
    width: 80%;
}

.width_85pr {
    width: 85%;
}

.width_90pr {
    width: 90%;
}

.width_90pr_95pr {
    width: 90%;
}

.width_95pr {
    width: 95%;
}

.width_98pr {
    width: 98%;
}

.width_100pr_i {
    width: 100% !important;
}

.width_100pr {
    width: 100%;
}

.width_100pr_250px {
    width: 100%;
}

.width_50vw {
    width: 50vw;
}

.width_70vw {
    width: 70vw;
}

.width_90vw {
    width: 90vw;
}

.width_100vw {
    width: 100vw;
}

.width__100pr {
    width: auto;
}

@media screen and (max-width: 1100px) {
    .width_700px_250px_150px {
        width: 250px;
    }

    .width_30pr_45pr_80pr {
        width: 45%;
    }

    .width_30pr_47pr_95pr {
        width: 47%;
    }

    .width_30pr_50pr_100pr {
        width: 50%;
    }

    .width_33pr_50pr_100pr {
        width: 50%;
    }

    .width_60pr_40pr_150px {
        width: 40%;
    }
}

@media screen and (max-width: 768px) {
    .width_auto_100pr {
        width: 100%;
    }

    .width_30px_20px {
        width: 20px;
    }

    .width_200px_120px {
        width: 120px;
    }

    .width_700px_250px_150px {
        width: 150px;
    }

    .width_700px_320px {
        width: 320px;
    }

    .width_500px_320px {
        width: 320px;
    }

    .min_width_500px_320px {
        min-width: 320px;
    }

    .width_30pr_50pr {
        width: 50%;
    }

    .width_30pr_100pr {
        width: 100%;
    }

    .width_30pr_45pr_80pr {
        width: 80%;
    }

    .width_30pr_47pr_95pr {
        width: 95%;
    }

    .width_30pr_50pr_100pr {
        width: 100%;
    }

    .width_33pr_50pr_100pr {
        width: 100%;
    }

    .width_40pr_100pr {
        width: 100%;
    }

    .width_45pr_95pr {
        width: 95%;
    }

    .width_48pr_95pr {
        width: 95%;
    }

    .width_50pr_30pr {
        width: 30%;
    }

    .width_50pr_100pr {
        width: 100%;
    }

    .width_60pr_100pr {
        width: 100%;
    }

    .width_60pr_40pr_150px {
        width: 150px;
    }

    .width_70pr_60pr {
        width: 60%;
    }

    .width_70pr_90pr {
        width: 90%;
    }

    .width_70pr_95pr {
        width: 95%;
    }

    .width_80pr_60pr {
        width: 60%;
    }

    .width_80pr_95pr {
        width: 95%;
    }

    .width_90pr_95pr {
        width: 95%;
    }
}

@media screen and (max-width: 568px) {
    .width_30px_100pr {
        width: 100%;
    }
    .width_100pr_250px {
        width: 250px;
    }

    .width_220px_100pr {
        width: 100%;
    }

    .width_300px_100pr {
        width: 100%;
    }

    .width__100pr {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .width_auto__100pr {
        width: 100%;
    }
}

@media screen and (max-width: 320px) {
    .min_width_200px_100pr {
        min-width: 200px;
    }

    .min_width_320px {
        min-width: 95%;
    }

    .width_500px_320px {
        width: 95%;
    }

    .min_width_500px_320px {
        min-width: 95%;
    }
}

/*HEIGHT*/

.height_auto {
    height: auto;
}

.height_2px {
    height: 2px !important;
}

.height_3px {
    height: 3px !important;
}

.height_5px {
    height: 5px !important;
}

.height_7px {
    height: 7px !important;
}

.height_10px {
    height: 10px !important;
}

.height_12px {
    height: 12px !important;
}

.height_15px {
    height: 15px !important;
}

.height_20px {
    height: 20px !important;
}

.height_23px {
    height: 23px !important;
}

.height_25px {
    height: 25px !important;
}

.height_30px {
    height: 30px !important;
}

.height_32px_28px {
    height: 32px !important;
}

.height_32px {
    height: 32px !important;
}

.height_35px {
    height: 35px !important;
}

.height_38px {
    height: 38px !important;
}

.height_40px_30px {
    height: 40px !important;
}

.height_40px {
    height: 40px !important;
}

.height_45px {
    height: 45px !important;
}

.height_50px {
    height: 50px !important;
}

.height_60px {
    height: 60px !important;
}

.height_70px {
    height: 70px !important;
}

.height_80px {
    height: 80px !important;
}

.height_100px {
    height: 100px !important;
}

.height_120px {
    height: 120px !important;
}

.height_140px {
    height: 140px !important;
}

.height_150px {
    height: 150px !important;
}

.height_200px {
    height: 200px;
}

.min_height_200px {
    min-height: 200px;
}

.min_height_250px {
    min-height: 250px;
}

.height_300px {
    height: 300px;
}

.min_height_300px {
    min-height: 300px;
}

.min_height_350px {
    min-height: 350px;
}

.height_380px_480px {
    height: 380px;
}

.height_400px {
    height: 400px;
}

.min_height_400px {
    min-height: 400px;
}

.height_420px {
    height: 420px;
}

.min_height_420px {
    min-height: 420px;
}

.height_430px {
    height: 430px;
}

.min_height_430px {
    min-height: 430px;
}

.height_450px {
    height: 450px;
}

.min_height_450px {
    min-height: 450px;
}

.height_480px {
    height: 480px;
}

.height_480px_auto {
    height: 480px;
}

.max_height_480px {
    max-height: 480px;
}

.height_500px {
    height: 500px;
}

.min_height_500px {
    min-height: 500px;
}

.height_650px {
    height: 500px;
}

.height_700px {
    height: 500px;
}

.height_800px {
    height: 800px;
}

.height_5pr {
    height: 5%;
}

.height_10pr {
    height: 10%;
}

.height_20pr {
    height: 20%;
}

.height_45pr {
    height: 45%;
}

.height_50pr {
    height: 50%;
}

.height_80pr {
    height: 80%;
}

.height_90pr {
    height: 90%;
}

.height_95pr {
    height: 95%;
}

.height_100pr {
    height: 100%;
}

.height_10vh {
    height: 10vh;
}

.height_70vh {
    height: 70vh;
}

.max_height_70vh {
    max-height: 70vh;
}

.height_80vh {
    height: 80vh;
}

.max_height_80vh {
    max-height: 80vh;
}

.height_90vh {
    height: 90vh;
}

.max_height_90vh {
    max-height: 90vh;
}

.height_92vh {
    height: 92vh;
}

.min_height_92vh {
    min-height: 92vh;
}

.max_height_92vh {
    max-height: 92vh;
}

.height_100vh {
    height: 91vh;
}

.height_100vh_test {
    height: 100vh;
}

.height_100vh_real {
    height: 100vh;
}

.min_height_100vh_real {
    height: 100vh;
}

@media screen and (max-width: 768px) {
    .height_480px_auto {
        height: auto;
    }
}

@media screen and (max-width: 463px) {
    .height_32px_28px {
        height: 28px !important;
    }

    .height_40px_30px {
        height: 30px !important;
    }

    .height_380px_480px {
        height: 480px;
    }
}

/*LISTS*/

ul.list_square {
    list-style-type: square;
}

.list_style_none {
    list-style: none;
}

/*TABLES*/

.max_table_height {
    max-height: calc(100vh - 80px);
}

.sticky_table {
    height: auto;
}

.sticky_table_thead {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    z-index: 10;
    top: 0;
}

.sticky_table_thead_tr {
    width: 100%;
    box-sizing: border-box;
}

.sticky_table_thead_tr th:first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    z-index: 15;
    box-sizing: border-box;
}

.sticky_table_tbody_tr {
    line-height: 1 !important;
}

.sticky_table_tbody_tr th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    z-index: 14;
    text-align: left;
    box-sizing: border-box;
    font-weight: 200;
}

.vertical_align_top {
    vertical-align: top;
}

.vertical_align_bottom {
    vertical-align: bottom;
}

/* .colspan_100pr {
    colspan: 100%;
} */

@media screen and (max-width: 568px) {
    .max_table_height {
        max-height: calc(100vh - 70px);
    }
}

@media screen and (max-width: 320px) {
    .max_table_height {
        max-height: calc(100vh - 60px);
    }
}

.direction_rtl {
    direction: rtl;
}

.direction_ltr {
    direction: ltr;
}

/* Creator Table */

.background_color_table_true {
    background-color: var(--background-color-table-true);
}

.background_color_table_true:hover {
    background-color: var(--background-color-table-true-hover);
}

.background_color_table_false {
    background-color: var(--background-color-table-false);
}

.background_color_table_false:hover {
    background-color: var(--background-color-table-false-hover);
}

.creator_sticky_table_tbody_tr th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    z-index: 9;
    text-align: left;
    box-sizing: border-box;
    font-weight: 200;
}

.creator_sticky_table_tbody_tr td {
    z-index: 7;
}

.test {
    color: #27b92e;
}

/*ELEMENTS*/

.divider {
    height: 1px;
    box-sizing: border-box;
    margin-top: 20px;
    border-bottom: 1px solid var(--border-color-1);
    width: 100%;
}

.divider_3px {
    height: 3px;
    box-sizing: border-box;
    margin-top: 20px;
    border-bottom: 3px solid var(--border-color-1);
    width: 100%;
}

.divider_no_margin {
    height: 1px;
    box-sizing: border-box;
    border-top: 1px solid var(--border-color-1);
    width: 100%;
}

.divider_no_margin_3px {
    height: 3px;
    box-sizing: border-box;
    margin-top: 0;
    border-top: 3px solid var(--border-color-1);
    width: 100%;
}

.divider_no_margin_dark {
    height: 1px;
    box-sizing: border-box;
    margin-top: 0;
    border-top: 1px solid rgb(59, 59, 59);
    width: 100%;
}

.divider_no_margin_dark_3px {
    height: 3px;
    box-sizing: border-box;
    margin-top: 0;
    border-top: 3px solid rgb(59, 59, 59);
    width: 100%;
}

.company_score_divider_thicc {
    height: 2px;
    background-color: var(--text-color-1);
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.company_score_divider_thin {
    height: 1px;
    background-color: var(--text-color-1);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 10px;
}

/*EFFECTS*/

.cursor_pointer {
    cursor: pointer;
}

.cursor_text {
    cursor: text;
}

.cursor_default {
    cursor: default;
}

.click_translate:active {
    box-shadow: 0 4px 8px 0 var(--shadow-vertical-color-1),
        0 6px 20px 0 var(--shadow-horizontal-color-1);
    transition: transform 0.1s ease-out;
    transform: translateY(3px);
}

.shadow_dark {
    box-shadow: 0 4px 8px 0 var(--shadow-vertical-color-1),
        0 6px 20px 0 var(--shadow-horizontal-color-1);
}

.transition_350ms {
    transition: 350ms;
}

.opacity_60 {
    opacity: 0.6;
}

.opacity_80 {
    opacity: 0.8;
}

.blur {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    /* background-color: #ccc; */
}

/*HOVER*/

.hover_translate_up {
    cursor: pointer;
    transition: transform 0.3s ease-out;
    display: inline-block;
}

.hover_translate_up:hover {
    transform: translate(0, -5px);
}

.hover_display_slave {
    display: none;
}

.hover_display_master:hover + .hover_display_slave {
    display: block;
}

.hover_opacity_60 {
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
}

.hover_opacity_60:hover {
    opacity: 0.6;
}

.hover_opacity_80 {
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
}

.hover_opacity_80:hover {
    opacity: 0.8;
}

.create_portfolio_icon:hover {
    box-sizing: border-box;
    border: 1px solid var(--text-color-1);
    border-radius: 8px;
    color: var(--text-color-1);
    background: var(--background-color-2);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

/*TRANSITION*/

.component_transition_opacity-enter {
    opacity: 0.01;
    transform: translate(-20px, 0);
    /* transform: scale(1.1); */
}

.component_transition_opacity-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 300ms;
}

.component_transition_opacity-exit {
    opacity: 1;
    transform: scale(1);
}

.component_transition_opacity-exit-active {
    opacity: 0.01;
    transform: translate(-20px, 0);
    /* transform: scale(1.1); */
    transition: all 200ms;
}

.component_transition_opacity_top-enter {
    opacity: 0.01;
    transform: translate(0, -50px);
    /* transform: scale(1.1); */
}

.component_transition_opacity_top-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 300ms;
}

.component_transition_opacity_top-exit {
    opacity: 1;
    transform: scale(1);
}

.component_transition_opacity_top-exit-active {
    opacity: 0.01;
    transform: translate(0, -50px);
    /* transform: scale(1.1); */
    transition: all 200ms;
}

/*SCROLLBAR*/

body {
    scrollbar-color: var(--scrollbar-color-thumb-1)
        var(--scrollbar-color-background-1);
    scrollbar-width: auto;
}
/* Firefox */
* {
    scrollbar-color: var(--scrollbar-color-thumb-1)
        var(--scrollbar-color-background-1);
    scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*::-webkit-scrollbar-track {
    background: var(--scrollbar-color-background-1);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color-thumb-1);
    /* border-radius: 20px; */
    /* border: 3px solid rgb(240, 240, 240); */
}

.scrollbar_invisible {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-y: scroll;
    overflow-x: hidden;
}
.scrollbar_invisible::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}

/* SCROLL BEHAVIOUR */

.scroll_behavior_smooth {
    scroll-behavior: smooth;
}

/*OVERFLOW*/

.overflow_auto {
    overflow: auto;
}

.overflow_x_auto {
    overflow-x: auto;
}

.overflow_x_scroll {
    overflow-x: scroll;
}

.overflow_x_hidden {
    overflow-x: hidden;
}

.overflow_y_auto {
    overflow-y: auto;
}

.overflow_y_hidden {
    overflow-y: hidden;
}

/*BORDERS*/

.border_1px_solid {
    border: 1px solid var(--border-color-1);
}

.border_3px_solid {
    border: 3px solid var(--border-color-1);
}

.border_1px_solid_black {
    border: 1px solid var(--border-color-2);
}

.border_1px_solid_grey {
    border: 1px solid var(--border-color-3);
}

.border_1px_solid_light_grey {
    border: 1px solid var(--text-color-2);
    opacity: 0.6;
}

.border_bottom_1px_solid_black {
    border-bottom: 1px solid var(--border-color-2);
}

.border_bottom_1px_solid_grey {
    border-bottom: 1px solid var(--border-color-1);
}

.border_bottom_1px_solid_blue {
    border-bottom: 1px solid var(--text-color-blue);
}

.border_left_1px_solid {
    border-left: 1px solid var(--border-color-1);
}

.border_right_1px_solid {
    border-right: 1px solid var(--border-color-1);
}

.border_radius_5px {
    border-radius: 5px;
}

.border_radius_5px_top {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.border_radius_5px_bottom {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.border_radius_10px {
    border-radius: 10px;
}

.border_radius_10px_right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.border_radius_15px {
    border-radius: 15px;
}

.border_radius_15px_top {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.border_radius_15px_bottom {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.border_radius_15px_left {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.border_radius_15px_right {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.border_radius_15px_bottom_left {
    border-bottom-left-radius: 15px;
}

.border_radius_15px_bottom_right {
    border-bottom-right-radius: 15px;
}

.border_radius_15px_top_left {
    border-top-left-radius: 15px;
}

.border_radius_15px_top_right {
    border-top-right-radius: 15px;
}

.border_radius_20px {
    border-radius: 20px;
}

.border_radius_25px {
    border-radius: 25px;
}

.border_radius_30px {
    border-radius: 30px;
}

/* ALERTS */

.alert {
    background-color: var(--background-color-white);
    color: var(--text-color-1);
}

.alert_danger {
    background-color: var(--background-color-red);
    color: var(--text-color-1);
}

.alert_warning {
    background-color: var(--background-color-orange);
    color: var(--text-color-1);
}

.alert_success {
    background-color: var(--background-color-green);
    color: var(--text-color-1);
}

.alert_notification {
    background-color: var(--background-color-blue);
    color: var(--text-color-1);
}

/* MENU */

.menu_hover {
    background-color: var(--background-color-7);
    transition: all 200ms;
}

.menu_hover:hover {
    background-color: var(--background-color-8);
    border-left: 4px solid var(--background-color-blue);
    transition: all 200ms;
}

.selected_menu_hover {
    background-color: var(--background-color-9);
    border-left: 4px solid var(--background-color-blue);
    transition: all 200ms;
}

.selected_menu_hover:hover {
    background-color: var(--background-color-8);
    border-left: 4px solid var(--background-color-blue);
    transition: all 200ms;
}

.submenu_hover {
    background-color: var(--background-color-8);
    transition: all 200ms;
}

.submenu_hover:hover {
    background-color: var(--background-color-9);
    border-left: 4px solid var(--background-color-blue);
    transition: all 200ms;
}

.selected_submenu_hover {
    background-color: var(--background-color-9);
    border-left: 4px solid var(--background-color-blue);
    transition: all 200ms;
}

.selected_submenu_hover:hover {
    background-color: var(--background-color-7);
    border-left: 4px solid var(--background-color-blue);
    transition: all 200ms;
}

/* Sidebar */

.sidebar_icon {
    box-sizing: border-box;
    grid-area: sidebar_icon;
    margin-right: 20px;
    justify-self: start;
    align-self: center;
}
.sidebar_title {
    box-sizing: border-box;
    grid-area: sidebar_title;
    justify-self: start;
    align-self: center;
}
.sidebar_subtitle {
    box-sizing: border-box;
    grid-area: sidebar_subtitle;
    justify-self: start;
    align-self: center;
}
.sidebar_icon_open_close {
    box-sizing: border-box;
    grid-area: sidebar_icon_open_close;
    justify-self: end;
    align-self: center;
}

/* Page Menu */
.page_sub_menu_flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.page_menu_item {
    font-size: 22px;
    margin-left: 40px;
}

.page_menu_link {
    transition: transform 0.3s ease-out;
    display: inline-block;
}

.page_menu_link:hover {
    transform: translate(0, -5px);
    color: var(--text-color-blue);
}
