/*OVERVIEW*/

.company_overview_table {
}

.company_overview_description {
}

/*STOCK PRICE*/

.company_overview_stock_price_options {
    box-sizing: border-box;
    height: 50px;
    /* background-color: white; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    overflow: visible !important;
}

.company_overview_stock_price {
    box-sizing: border-box;
    height: 500px;
    /* background-color: white; */
    overflow: visible !important;
    z-index: 100000;
}

.company_overview_stock_price_max_height {
    max-height: 500px;
    overflow: visible !important;
}

/*SCORE*/
/* 
.company_score_piotroski_f_full {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    width: 100%;
    background-color: white;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-areas:
        'score_header_2'
        'score_rows'
        'score_header_2'
        'score_rows'
        'score_header_2'
        'score_rows';
}

.company_score_rows {
    box-sizing: border-box;
    min-width: 400px;
    width: 100%;
    grid-area: score_rows;
    display: grid;
    grid-template-columns: 40% 30% 15% 15%;
    grid-template-rows: auto;
} */

.company_score_container {
    height: auto;
    width: 400px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.compay_score_body_section_body_grid {
    display: grid;
    grid-template-columns: 40% 40% 10% 10%;
}
