/*SURVEY RISK ASSESSMENT*/

.hide_sub_667px {
    display: block;
}

.show_sub_667px {
    display: none;
}

@media screen and (max-width: 420px) {
    .hide_sub_667px {
        display: none;
    }

    .show_sub_667px {
        display: block;
    }
}

/*PIECHART*/

.hidden_asset_allocation {
    display: none;
    position: absolute;
    z-index: 20;
    margin-top: 70px;
    background-color: grey;
    border-radius: 8px;
    height: 34px;
    width: 200px;
    color: white;
    padding-top: 5px;
    text-align: center;
}

.shown_asset_allocation:hover + .hidden_asset_allocation {
    display: block;
    color: black;
}

.hidden_country_allocation {
    display: none;
    position: absolute;
    z-index: 20;
    margin-top: 70px;
    background-color: grey;
    border-radius: 8px;
    height: 34px;
    width: 200px;
    color: white;
    padding-top: 5px;
    text-align: center;
}

.shown_country_allocation:hover + .hidden_country_allocation {
    display: block;
    color: black;
}

.hidden_industry_allocation {
    display: none;
    position: absolute;
    z-index: 20;
    margin-top: 70px;
    background-color: grey;
    border-radius: 8px;
    height: 34px;
    width: 200px;
    color: white;
    padding-top: 5px;
    text-align: center;
}

.shown_industry_allocation:hover + .hidden_industry_allocation {
    display: block;
    color: black;
}

.portfolio_assets_donut_statistics {
    width: 500px;
}

@media screen and (max-width: 1100px) {
    .portfolio_assets_donut_statistics {
        width: 500px;
    }
}

@media screen and (max-width: 800px) {
    .portfolio_assets_donut_statistics {
        width: 95%;
        min-width: 320px;
        max-width: 500px;
    }
}
