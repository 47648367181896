.portfolio_grid {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    width: 100%;
    background-color: #f7f9fc;
    display: grid;
    grid-template-columns: 30% 30% 40%;
    grid-template-rows: 300px 200px 500px;
    grid-template-areas:
        "assets chart chart"
        "assets chart chart"
        "aiinterested aioptimized figures";
}

.portfolio_contained_assets {
    box-sizing: border-box;
    margin: 20px;
    grid-area: assets;
}

.portfolio_performance_chart {
    box-sizing: border-box;
    margin: 20px;
    grid-area: chart;
}

.portfolio_performance_figures {
    box-sizing: border-box;
    margin: 20px;
    grid-area: figures;
}

.portfolio_ai_interested {
    box-sizing: border-box;
    margin: 20px;
    grid-area: aiinterested;
    color: white;
}

.portfolio_ai_optimized {
    box-sizing: border-box;
    margin: 20px;
    grid-area: aioptimized;
    color: white;
}

/*CHART*/

/*CREATOR*/
.portfolio_creator_container {
    position: relative;
    min-height: 550px;
    height: auto;
    padding-bottom: 100px;
}

.portfolio_creator_questionnaire_buttons {
    width: 100%;
    position: absolute;
    bottom: 30px;
}

/*MANAGER*/

/* @media screen and (max-width: 1300px) {
} */
