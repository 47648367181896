/*Dialog*/

.screener_dialog_wrapper {
    width: 80%;
    max-width: 1250px;
    min-height: 500px;
}

.height_screener_options_close {
    height: 100%;
}

@media screen and (max-width: 400px) {
    .height_screener_options_close {
        height: 130px;
    }
}

/*Searchbar*/

/*Screener-Slider*/

.screener_all_sliders_container {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    min-width: 320px;
    margin-top: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

/*Slider Element*/

.screener_slider_grid {
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    height: 60px;
    display: grid;
    grid-template-columns: 30px 140px auto 40px;
    grid-template-rows: 60px;
    grid-template-areas: "checkbox label range icons";
}

.screener_slider_checkbox {
    box-sizing: border-box;
    grid-area: checkbox;
    place-self: center;
}

.screener_slider_label {
    box-sizing: border-box;
    grid-area: label;
    align-self: center;
}

.screener_slider_range_grid {
    box-sizing: border-box;
    grid-area: range;
    place-self: center;
    width: 100%;
    display: grid;
    grid-template-columns: 19% 62% 0% 19%;
    grid-template-rows: 60px;
    grid-template-areas: "min selector placeholder max";
}

.screener_min_value {
    box-sizing: border-box;
    grid-area: min;
    place-self: center;
}

.screener_placeholder {
    display: none;
    grid-area: placeholder;
}

.screener_selector {
    box-sizing: border-box;
    grid-area: selector;
    place-self: center;
}

.screener_max_value {
    box-sizing: border-box;
    grid-area: max;
    place-self: center;
}

.screener_icon_large {
    box-sizing: border-box;
    grid-area: icons;
    display: grid;
    min-width: 40px;
    grid-template-columns: 20px 20px;
    grid-template-rows: 30px 30px;
    grid-template-areas:
        ". widen"
        "info reset";
}

.screener_icon_widen {
    box-sizing: border-box;
    grid-area: widen;
    place-self: center;
}

.screener_icon_reset {
    box-sizing: border-box;
    grid-area: reset;
    place-self: center;
}

.screener_icon_info {
    box-sizing: border-box;
    grid-area: info;
    place-self: center;
}

@media screen and (min-width: 1100px) {
    .screener_slider_grid {
        width: 50%;
    }
}

@media screen and (max-width: 738px) {
    .screener_slider_grid {
        height: 70px;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        /* border-top: 1px solid rgb(233, 233, 233); */
        display: grid;
        grid-template-columns: 25px auto auto 30px 30px;
        grid-template-rows: 35px 35px;
        grid-template-areas:
            "checkbox label label icons icons"
            "range range range range range";
    }

    .screener_slider_checkbox {
        box-sizing: border-box;
        padding-left: 10px;
        grid-area: checkbox;
        place-self: center;
    }

    .screener_slider_label {
        box-sizing: border-box;
        padding-left: 10px;
        grid-area: label;
        align-self: center;
    }

    .screener_slider_range_grid {
        box-sizing: border-box;
        grid-area: range;
        padding-bottom: 10px;
        place-self: center;
        width: 100%;
        display: grid;
        grid-template-columns: 15% 70% 0% 15%;
        grid-template-rows: 35px;
        grid-template-areas: "min selector placeholder max";
    }

    .screener_min_value {
        box-sizing: border-box;
        grid-area: min;
        place-self: center;
    }

    .screener_placeholder {
        display: none;
        grid-area: placeholder;
    }

    .screener_selector {
        box-sizing: border-box;
        grid-area: selector;
        place-self: center;
    }

    .screener_max_value {
        box-sizing: border-box;
        grid-area: max;
        place-self: center;
    }

    .screener_icon_large {
        box-sizing: border-box;
        grid-area: icons;
        padding-right: 10px;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-template-rows: 35px;
        grid-template-areas: "info widen reset";
    }

    .screener_icon_widen {
        box-sizing: border-box;
        grid-area: widen;
        place-self: center;
    }

    .screener_icon_reset {
        box-sizing: border-box;
        grid-area: reset;
        place-self: center;
    }

    .screener_icon_info {
        box-sizing: border-box;
        grid-area: info;
        place-self: center;
    }
}

@media screen and (max-width: 400px) {
    .screener_slider_grid {
        height: 82px;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        /* border-top: 1px solid rgb(233, 233, 233); */
        display: grid;
        grid-template-columns: 25px auto auto 30px 30px;
        grid-template-rows: 32px 25px 25px;
        grid-template-areas:
            "checkbox label label icons icons"
            "range range range range range"
            "range range range range range";
    }

    .screener_slider_checkbox {
        box-sizing: border-box;
        padding-left: 10px;
        grid-area: checkbox;
        place-self: center;
    }

    .screener_slider_label {
        box-sizing: border-box;
        padding-left: 10px;
        grid-area: label;
        align-self: center;
    }

    .screener_slider_range_grid {
        box-sizing: border-box;
        grid-area: range;
        padding-bottom: 10px;
        place-self: center;
        width: 100%;
        display: grid;
        grid-template-columns: 25% 50% 25%;
        grid-template-rows: 25px 25px;
        grid-template-areas:
            "min placeholder max"
            "selector selector selector";
    }

    .screener_min_value {
        box-sizing: border-box;
        grid-area: min;
        place-self: center;
    }

    .screener_placeholder {
        box-sizing: border-box;
        grid-area: placeholder;
        place-self: center;
    }

    .screener_selector {
        box-sizing: border-box;
        grid-area: selector;
        place-self: center;
    }

    .screener_max_value {
        box-sizing: border-box;
        grid-area: max;
        place-self: center;
    }

    .screener_icon_large {
        box-sizing: border-box;
        grid-area: icons;
        padding-right: 10px;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-template-rows: 35px;
        grid-template-areas: "info widen reset";
    }

    .screener_icon_widen {
        box-sizing: border-box;
        grid-area: widen;
        place-self: center;
    }

    .screener_icon_reset {
        box-sizing: border-box;
        grid-area: reset;
        place-self: center;
    }

    .screener_icon_info {
        box-sizing: border-box;
        grid-area: info;
        place-self: center;
    }
}

/*Screener Table Structure*/

.screener_table_structure_container {
    display: flex;
    width: 100%;
    min-width: 350px;
    margin-top: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.screener_table_structure_grid {
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% / 3);
    height: 50px;
    display: grid;
    grid-template-columns: 40px auto 60px;
    grid-template-rows: 50px;
    grid-template-areas: "table_checkbox table_label table_icons";
}

.screener_table_structure_checkbox {
    box-sizing: border-box;
    grid-area: table_checkbox;
    place-self: center;
}

.screener_table_structure_label {
    box-sizing: border-box;
    grid-area: table_label;
    align-self: center;
}

.screener_table_structure_icon_large {
    box-sizing: border-box;
    grid-area: table_icons;
    display: grid;
    grid-template-columns: 30px 30px;
    grid-template-rows: 50px;
    grid-template-areas: "table_info table_widen";
}

.screener_table_structure_icon_widen {
    box-sizing: border-box;
    grid-area: table_widen;
    place-self: center;
}

.screener_icon_table_structure_info {
    box-sizing: border-box;
    grid-area: table_info;
    place-self: center;
}

.screener_select_form_tableStructure {
    min-width: 300px;
}

.screener_filter_presets_dropdown {
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 2px solid #00498e;
    border-radius: 10px;
}

@media screen and (max-width: 1100px) {
    .screener_table_structure_grid {
        box-sizing: border-box;
        padding-left: 10px;
        padding-right: 10px;
        width: 50%;
        height: 50px;
        display: grid;
        grid-template-columns: 40px auto 60px;
        grid-template-rows: 50px;
        grid-template-areas: "table_checkbox table_label table_icons";
    }
}

@media screen and (max-width: 800px) {
    .screener_table_structure_grid {
        box-sizing: border-box;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
        height: 50px;
        display: grid;
        grid-template-columns: 40px auto 60px;
        grid-template-rows: 50px;
        grid-template-areas: "table_checkbox table_label table_icons";
    }
}

@media screen and (max-width: 450px) {
    .screener_table_structure_grid {
        box-sizing: border-box;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        height: 50px;
        display: grid;
        grid-template-columns: 30px auto 30px;
        grid-template-rows: 50px;
        grid-template-areas: "table_checkbox table_label table_icons";
    }

    .screener_table_structure_icon_large {
        box-sizing: border-box;
        grid-area: table_icons;
        display: grid;
        grid-template-columns: 30px;
        grid-template-rows: 25px 25px;
        grid-template-areas:
            "table_info"
            "table_widen";
    }
}

/*Infobox*/

.slider_info_wrapper_small {
    box-sizing: border-box;
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
    min-width: 250px;
    max-width: 700px;
}

.slider_info_wrapper {
    box-sizing: border-box;
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    min-width: 250px;
    max-width: 900px;
}

.slider_info_container {
    box-sizing: border-box;
}

.slider_info_header {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slider_info_typical_values {
    margin: 10px;
}

@media screen and (max-width: 738px) {
    .slider_info_wrapper_small {
        box-sizing: border-box;
        width: 70vw;
        margin-left: auto;
        margin-right: auto;
        min-width: 250px;
        max-width: 700px;
    }

    .slider_info_wrapper {
        box-sizing: border-box;
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
        min-width: 250px;
        max-width: 900px;
    }
}

@media screen and (max-width: 568px) {
    .slider_info_wrapper_small {
        box-sizing: border-box;
        width: 100vw;
        margin-left: auto;
        margin-right: auto;
        min-width: 250px;
        max-width: 700px;
    }

    .slider_info_wrapper {
        box-sizing: border-box;
        width: 100vw;
        margin-left: auto;
        margin-right: auto;
        min-width: 250px;
        max-width: 900px;
    }
}

/*Histogram*/

.screener_slider_range_grid_histogram {
    box-sizing: border-box;
    grid-area: range;
    place-self: center;
    display: grid;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0px;
    width: 100%;
    grid-template-columns: 20% 60% 0% 20%;
    grid-template-rows: 60px;
    grid-template-areas: "min_histogram selector_histogram placeholder_histogram max_histogram";
}

.screener_min_value_histogram {
    box-sizing: border-box;
    grid-area: min_histogram;
    place-self: center;
}

.screener_placeholder_histogram {
    grid-area: placeholder_histogram;
}

.screener_selector_histogram {
    box-sizing: border-box;
    grid-area: selector_histogram;
    place-self: center;
}

.screener_max_value_histogram {
    box-sizing: border-box;
    grid-area: max_histogram;
    place-self: center;
}

@media screen and (max-width: 738px) {
    .screener_slider_range_grid_histogram {
        box-sizing: border-box;
        grid-area: range;
        place-self: center;
        display: grid;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 0px;
        width: 100%;
        grid-template-columns: 23% 54% 0% 23%;
        grid-template-rows: 60px;
        grid-template-areas: "min_histogram selector_histogram placeholder_histogram max_histogram";
    }

    .screener_min_value_histogram {
        box-sizing: border-box;
        grid-area: min_histogram;
        place-self: center;
    }

    .screener_placeholder_histogram {
        grid-area: placeholder_histogram;
    }

    .screener_selector_histogram {
        box-sizing: border-box;
        grid-area: selector_histogram;
        place-self: center;
    }

    .screener_max_value_histogram {
        box-sizing: border-box;
        grid-area: max_histogram;
        place-self: center;
    }
}

@media screen and (max-width: 568px) {
    .screener_slider_range_grid_histogram {
        box-sizing: border-box;
        grid-area: range;
        place-self: center;
        display: grid;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 10px;
        width: 100%;
        grid-template-columns: 25% 50% 25%;
        grid-template-rows: 25px 25px;
        grid-template-areas:
            "min_histogram placeholder_histogram max_histogram"
            "selector_histogram selector_histogram selector_histogram";
    }

    .screener_min_value_histogram {
        box-sizing: border-box;
        grid-area: min_histogram;
        place-self: center;
    }

    .screener_placeholder_histogram {
        display: block;
        grid-area: placeholder_histogram;
    }

    .screener_selector_histogram {
        box-sizing: border-box;
        grid-area: selector_histogram;
        place-self: center;
    }

    .screener_max_value_histogram {
        box-sizing: border-box;
        grid-area: max_histogram;
        place-self: center;
    }
}

/* HISTOGRAM */

.histogram_legend_circle {
    width: 14px;
    height: 14px;
    border-radius: 7px;
}

.histogram_statistics_table_body_row {
    height: 45px;
}

.histogram_statistics_table_body_column {
    width: 100px;
}

/* DCF-SLIDER */

.dcf_slider_slider_grid {
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 98%;
    height: 60px;
    display: grid;
    grid-template-columns: 150px auto 40px;
    grid-template-rows: 60px;
    grid-template-areas: "dcf_slider_label dcf_slider_range dcf_slider_icons";
}

.dcf_slider_slider_label {
    box-sizing: border-box;
    grid-area: dcf_slider_label;
    align-self: center;
}

.dcf_slider_slider_range_grid {
    box-sizing: border-box;
    grid-area: dcf_slider_range;
    padding-left: 20px;
    padding-right: 20px;
    place-self: center;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(auto, 80%) minmax(100px, 15%);
    grid-gap: 5%;
    grid-template-rows: 60px;
    grid-template-areas: "dcf_slider_selector dcf_slider_max";
}

.dcf_slider_selector {
    box-sizing: border-box;
    grid-area: dcf_slider_selector;
    place-self: center;
}

.dcf_slider_value {
    box-sizing: border-box;
    grid-area: dcf_slider_max;
    align-self: center;
    justify-self: end;
}

.dcf_slider_icon_large {
    box-sizing: border-box;
    grid-area: dcf_slider_icons;
    display: grid;
    min-width: 40px;
    grid-template-columns: 40px;
    grid-template-rows: 30px 30px;
    grid-template-areas:
        "dcf_slider_info"
        "dcf_slider_reset";
}

.dcf_slider_icon_widen {
    box-sizing: border-box;
    grid-area: dcf_slider_widen;
    place-self: center;
}

.dcf_slider_icon_reset {
    box-sizing: border-box;
    grid-area: dcf_slider_reset;
    place-self: center;
}

.dcf_slider_icon_info {
    box-sizing: border-box;
    grid-area: dcf_slider_info;
    place-self: center;
}

/* @media screen and (min-width: 1100px) {
    .dcf_slider_slider_grid {
        width: 49%;
    }
} */

@media screen and (max-width: 1100px) {
    .dcf_slider_slider_grid {
        height: 70px;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: auto auto 20px 20px;
        grid-template-rows: 35px 35px;
        grid-template-areas:
            "dcf_slider_label dcf_slider_label dcf_slider_icons dcf_slider_icons"
            "dcf_slider_range dcf_slider_range dcf_slider_range dcf_slider_range";
    }

    .dcf_slider_slider_label {
        box-sizing: border-box;
        padding-left: 10px;
        grid-area: dcf_slider_label;
        align-self: center;
    }

    /* .dcf_slider_slider_range_grid {
        box-sizing: border-box;
        grid-area: dcf_slider_range;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        place-self: center;
        width: 100%;
    } */

    .dcf_slider_slider_range_grid {
        box-sizing: border-box;
        grid-area: dcf_slider_range;
        padding-left: 10px;
        padding-right: 10px;
        place-self: center;
        width: 100%;
        display: grid;
        grid-template-columns: minmax(auto, 75%) minmax(80px, 20%);
        grid-gap: 5%;
        grid-template-rows: 60px;
        grid-template-areas: "dcf_slider_selector dcf_slider_max";
    }

    .dcf_slider_selector {
        box-sizing: border-box;
        grid-area: dcf_slider_selector;
        place-self: center;
    }

    .dcf_slider_value {
        box-sizing: border-box;
        grid-area: dcf_slider_max;
        align-self: center;
        justify-self: end;
    }

    .dcf_slider_icon_large {
        box-sizing: border-box;
        grid-area: dcf_slider_icons;
        padding-right: 25px;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 35px;
        grid-template-areas: "dcf_slider_info dcf_slider_widen dcf_slider_reset";
    }

    .dcf_slider_icon_widen {
        box-sizing: border-box;
        grid-area: dcf_slider_widen;
        place-self: center;
    }

    .dcf_slider_icon_reset {
        box-sizing: border-box;
        grid-area: dcf_slider_reset;
        place-self: center;
    }

    .dcf_slider_icon_info {
        box-sizing: border-box;
        grid-area: dcf_slider_info;
        place-self: center;
    }
}
